import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api.js'

Vue.use(Vuex)

const DEF_ORDER = {
  d: "200",
  p: "100",
  oc: null,
  ot: null,
  dc: 0,
  email: null,
  addr: null,
  name: null,
  regnr: null,
  phone: null
}

export default new Vuex.Store({
  state: {
    order: {
      ...DEF_ORDER
    },
    basket: [],
    omniva: [],
    designs: []
  },
  getters: {
    basket: state => {
      if (!state.basket || state.basket.length == 0) {
        try {
          const src = sessionStorage.getItem('b');
          state.basket = src ? [...JSON.parse(src)] : [];
        } catch (error) {
          console.log(error)
          state.basket = []
        }
      }
      return state.basket;
    },
    omniva: state => {
      return state.omniva;
    },
    isOmniva: state => {
      return state.omniva.length > 0;
    },
    designs: state => {
      return state.designs;
    },
    design: state => {
      return (id) => {
          if(!id) id = 0;
          const o = state.designs.find(e => {
              return e.id == id
          });
          return o ? o : {};
      }
  },
  isDesigns: state => {
      return state.designs.length > 0;
    },
    omnivaId: state => {
      return (id) => {
        const o = state.omniva.find(e => {
          return e.ZIP == id
        });
        return o ? o : {};
      }
    },
    order: state => {
      return state.order;
    },
  },
  mutations: {
    add2basket: (state, payload) => {
      try {
        state.basket.push(payload)
        // const prev = state.basket.findIndex(e => e.ci == payload.item.ci);
        // if (prev == -1) {
        //          payload.item.qty = payload.qty;
        //   state.basket.push(payload.item)
        // } else {
        //   const el = state.basket[prev];
        //   if (!el.qty) el.qty = 1;
        //   el.qty += payload.qty;
        //   if (el.qty < 1) {
        //     state.basket.splice(prev, 1);
        //   } else {
        //     state.basket.splice(prev, 1, el);
        //   }
        // }
        sessionStorage.setItem('b', JSON.stringify(state.basket));
      } catch (error) {
        console.log(error)
      }
    },
    delCard: (state, payload) => {
      try {
        const i = state.basket.indexOf(payload);
        if (i != -1) {
          state.basket.splice(i, 1);
        }
        // if (prev == -1) {
        //          payload.item.qty = payload.qty;
        //   state.basket.push(payload.item)
        // } else {
        //   const el = state.basket[prev];
        //   if (!el.qty) el.qty = 1;
        //   el.qty += payload.qty;
        //   if (el.qty < 1) {
        //     state.basket.splice(prev, 1);
        //   } else {
        //     state.basket.splice(prev, 1, el);
        //   }
        // }
        sessionStorage.setItem('b', JSON.stringify(state.basket));
      } catch (error) {
        console.log(error)
      }
    },
    reloadBasket: (state) => {
      try {
        const src = sessionStorage.getItem('b');
        state.basket = src ? [...JSON.parse(src)] : [];
        const src1 = sessionStorage.getItem('o');
        state.order = src1 ? {
          ...JSON.parse(src1)
        } : DEF_ORDER;
      } catch (error) {
        state.basket = []
      }
    },
    clearOrder: (state) => {
      state.basket = []
      state.order = {
        ...DEF_ORDER
      };
      sessionStorage.clear();
    },
    updateOrder: (state, payload) => {
      state.order = {
        ...payload
      };
      sessionStorage.setItem('o', JSON.stringify(payload));
    },
    updateBasket: (state, payload) => {
      state.basket = [...payload];
      sessionStorage.setItem('b', JSON.stringify(payload));
    },
    setOmniva: (state, payload) => {
      try {
        state.omniva = payload.data.filter(e => e.A0_NAME == "LV");
      } catch (error) {
        state.omniva = []
      }
    },
    setDesigns: (state, payload) => {
      try {
        state.designs = [...payload];
      } catch (error) {
        state.designs = []
      }
    }
  },
  actions: {
    LOAD_PASS_DATA: function (context, data) {
      return new Promise((resolve) => {
        api.sendCodeString(data).then(response => {
          const item = {
            ...response,
            qrcode: data
          }
          context.commit("add2basket", item);
          resolve(item);
        })
      })
    },
    PREPARE_ORDER: function (context, hash) {
      return new Promise((resolve) => {
        api.prepareOrder({
          hash: hash,
          items: context.state.basket,
          order: context.state.order,
        }).then((response) => {
          if (response.order) {
            context.commit("clearOrder");
            resolve(response);
          }
        });
      })
    },
    LOAD_ORDER: function (context, order) {
      return new Promise((resolve, reject) => {
        api.loadOrder(order).then((response) => {
          if (response.order) {
            context.commit("updateOrder", response.order);
            context.commit("updateBasket", response.items.items);
            resolve(response);
          }
        }).catch((error) => {
          reject(error);
        });
      })
    },
    PURCHASE: function (context, data) {
      return new Promise((resolve) => {
        api.confirmOrder(
          data.order, data.data
        ).then((response) => {
          if (response) {
            context.commit("clearOrder");
            resolve(response);
          }
        });

      })
    },
    RELOAD_BASKET: function (context) {
      context.commit("reloadBasket");
    },
    LOAD_OMNIVA: function (context) {
      api.getOmnivaList().then(response => {
        context.commit("setOmniva", response);
      })
    },
    LOAD_DESIGNS: function (context) {
      api.getDesignList().then(response => {
        context.commit("setDesigns", response);
      })
    }
  },
})