<template>
  <v-sheet class="grow-height">
    <v-card flat class="background-sheet grow-height">
      <v-card-title class="justify transparent">
        <div v-if="$vuetify.breakpoint.xs">
          <div>
            <i18n path="home.title1" />
          </div>
          <div>
            <i18n path="home.title2" />
          </div>
        </div>
        <div v-else class="below">
          <div class="title-line-1 font-weight-black">
            <i18n path="home.title1" />
          </div>
          <div class="title-line-2 my-3 font-weight-black">
            <i18n path="home.title2" />
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" class="transparent">
          <v-col cols="12" md="3">
            <div>
              <v-img src="img/ColorCards.gif" class="card-video" @click="$router.push('/basket')"></v-img>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="text-justify">
            <div class="main-text" v-html="$t('home.main1')">
              <!-- <i18n path="home.main1" /> -->
            </div>
            <div class="main-text mt-2">
              <i18n path="home.main2" />
            </div>
            <div class="main-text mt-2" v-if="$vuetify.breakpoint.xs">
              <i18n path="home.main3" />
            </div>
          </v-col>
          <v-col cols="12" md="7" class="hide-stepper">
            <v-row>
              <v-col cols="12" class="text-center">
                <v-img :src="$t('images.pasutit')" class="pasutit1-btn" @click="$router.push('/basket')"></v-img>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                  <v-timeline-item
                    v-for="i in 3"
                    :key="i"
                    :color="$t(`stepper.step${i}.color`)"
                    fill-dot
                  >
                    <template #icon>
                      <v-icon color="white" v-text="$t(`stepper.step${i}.icon`)" />
                    </template>
                    <template #default>
                      <v-card
                        :color="$t(`stepper.step${i}.color`)"
                        dark
                        @click="$router.push('/basket')"
                        class="step-card"
                      >
                        <v-card-title class="text-h6 text-break">
                          <i18n :path="`stepper.step${i}.title`" />
                        </v-card-title>
                        <v-card-text
                          class="white text--primary pt-3 text-justify"
                          v-html="$t(`stepper.step${i}.text`)"
                        ></v-card-text>
                      </v-card>
                    </template>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
              <v-col cols="12" class="text-center mt-n6">
                <v-img src="img/resultcard.png" class="resultcard" @click="$router.push('/basket')"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row></v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn to="/basket" right absolute class="start-btn elevation-7">Pasūtīt</v-btn> -->
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-img
      src="img/pasutit.png"
      class="pasutit-btn mini-btn"
      @click="$router.push('/basket')"
      v-if="$vuetify.breakpoint.smAndDown"
    ></v-img>
  </v-sheet>
</template>

<script>
export default {
  name: "Home-view",
};
</script>
<style scoped>
.below {
  /* white-space: nowrap; */
  word-break: break-word;
  /* top: 7%;
  left: 50%; */
  /* color: cadetblue; */
  /* font-size: 4vw; */
  color: #147e18;
  font-size: 2.5vw;
  margin-top: 30px;
}
.card-video {
  cursor: pointer;
  width: 240px;
  height: 180px;
  margin: auto;
}
.resultcard {
  width: 200px;
  margin: auto;
  border-radius: 5%;
}
.resultcard:hover {
  -webkit-box-shadow: 5px 5px 15px 5px rgba(33, 30, 30, 0.6);
  box-shadow: 5px 5px 15px 5px rgba(33, 30, 30, 0.6);
  cursor: pointer;
}
.start-btn {
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(187, 255, 138, 1) 0%,
    rgba(255, 255, 255, 1) 51%,
    rgba(144, 249, 173, 1) 100%
  );
  position: fixed;
  bottom: 0;
  margin-bottom: 40px;
  margin-right: 40px;
}
.pasutit-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 60px;
  margin-right: 40px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    5px 5px 15px 5px rgb(0 0 0 / 0%);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
  animation-name: stretch;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.pasutit1-btn {
  width: 144px;
  margin: auto;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    5px 5px 15px 5px rgb(0 0 0 / 0%);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
  animation-name: stretch;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.pasutit1-btn:hover {
  animation-play-state: paused;
  animation-iteration-count: unset;
}
.step-card:hover {
  -webkit-box-shadow: 5px 5px 15px 5px rgba(33, 30, 30, 0.6) !important;
  box-shadow: 5px 5px 15px 5px rgba(33, 30, 30, 0.6) !important;
  cursor: pointer;
}
.title-line-1 {
  letter-spacing: 12px;
}
.title-line-2 {
  letter-spacing: 15.5px;
}
.mini-btn {
  display: none;
}
.main-text {
  text-indent: 35px;
  color: black;
  font-size: larger;
}
@media (-webkit-max-device-pixel-ratio: 2) {
  .background-sheet {
    background-image: url("../../public/img/idcs_logo2.svg");
    /* background-size: 50px; */
    /* background-repeat: repeat-x repeat-y; */
    background-repeat-x: repeat;
    background-size: 235px;
    background-repeat-y: repeat;
    /* background: #e7e6eed7; */
  }
}
@media (-webkit-min-device-pixel-ratio: 2.2) {
.main-text {
  text-indent: 20px;
  color: black;
  font-size: 13px;
}
.pasutit1-btn {
  width: 100px;
  margin: auto;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    5px 5px 15px 5px rgb(0 0 0 / 0%);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
  animation-name: stretch;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
  .background-sheet {
    background-image: url("../../public/img/idcs_logo2.svg");
    /* background-size: 50px; */
    /* background-repeat: repeat-x repeat-y; */
    background-repeat-x: repeat;
    background-size: 235px;
    background-repeat-y: repeat;
    /* background: #e7e6eed7; */
  }
  /* .hide-stepper {
    display: none;
  } */

  .mini-btn {
    width: 100px;
  }
  .title-line-1 {
    margin-top: 1px;
    /* letter-spacing: 12px;  */
  }
  .title-line-2 {
    margin-top: 1px;
    /* letter-spacing: 15.5px;  */
  }
  .below {
    /* white-space: nowrap; */
    word-break: break-word;
    /* top: 7%;
  left: 50%; */
    /* color: cadetblue; */
    /* font-size: 4vw; */
    color: #147e18;
    /* font-size: 2.5vw; */
  }
  .card-video {
    width: 160px;
    height: 120px;
  }
}

@keyframes stretch {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
