export default function currency(val) {
    let v = parseInt(val);
    if (isNaN(v)) return "";
    let nStr = (v / 100).toString();
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] + (x[1].length == 1 ? "0" : "") : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + " " + "$2");
    }
    return x1 + x2 + " €";
}