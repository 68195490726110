import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/basket',
  //   name: 'Basket',
  //   component: () => import('@/views/Basket.vue')
  // },
  {
    path: '/basket/:id?',
    name: 'Basket',
    component: () => import('@/views/Basket.vue')
  },
  {
    path: '/politika',
    name: 'Politika',
    component: () => import('@/views/Politika.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('@/views/Order.vue')
  },
  {
    path: '/accept/:id',
    name: 'Accept',
    component: () => import('@/views/Accept.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
