<template>
  <v-app>
    <v-app-bar app color="#173799" dark>
      <v-img
        src="@/assets/es.png"
        max-width="50px"
        max-height="50px"
        @click="$router.push('/')"
        class="as-pointer"
      ></v-img>
      <v-app-bar-title>
        <i18n path="global.title" class="pl-4" />
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon to="/">
        <v-icon>mdi-home</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon to="/politika">
        <v-icon>mdi-information-outline</v-icon>
      </v-app-bar-nav-icon>
      <select-locale-menu class="mr-n6"/>
      <!-- <v-app-bar-nav-icon to="/basket">
        <v-icon>mdi-basket</v-icon>
      </v-app-bar-nav-icon>-->
    </v-app-bar>
    <v-main>
      <router-view />
      <v-snackbar v-model="show" :color="mColor" transition="v-scroll-x-transition" shaped>
        {{ mText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="show=false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-footer app class="mini-foot justify">
      <div>
        <i18n path="global.copy" class="pr-2" />
        <i18n path="global.addr" class="pr-2" />
      </div>
      <div>
        <v-icon x-small class="px-1">mdi-email</v-icon>
        <i18n path="global.mail" class="px-1" />
        <v-icon x-small class="px-1">mdi-phone</v-icon>
        <i18n path="global.phone" />
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    "select-locale-menu": () => import("@/components/Select-locale.vue"),
  },

  data: () => ({
    mText: null,
    mColor: "primary",
    show: false,
  }),
  mounted() {
    this.$root.$on("showmsg", (e) => {
      this.mText = e.msg;
      if (e.color) this.mColor = e.color;
      this.show = true;
    });
  },
};
</script>
<style>
.as-pointer {
  cursor: pointer;
}
.card-img {
  width: 50px;
  margin-right: 8px;
}

.ptindent {
  text-indent: 35px;
  margin-top: 5px;
}
.ptlink {
  color: blue;
  text-decoration: underline;
}

.justify {
  justify-content: center;
}
.fluid {
  width: 100%;
}
@media (-webkit-min-device-pixel-ratio: 2.1) {
  .mini-foot {
    font-size: 10px;
  }
}
</style>
