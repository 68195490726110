import axios from "axios";
//const API = process.env.NODE_ENV == "development" ? "http://192.168.112.114:4567/vcapi" : "https://service.elektroniskaskartes.lv/vcapi";
const API = "https://service.elektroniskaskartes.lv/vcapi";

const vcapi = axios.create({
    withCredentials: true,
    baseURL: API,
});
const api = {
    api: API,
    getOmnivaList() {
        return axios.get("https://www.omniva.lv/locations.json");
    },
    getDesignList() {
        return this.apiGetRequest("/cvd/v1/designs");
    },
    getCovidOrderResource(hash, resource) {
        return this.apiGetRequest("/cvd/v1/order/resource/" + hash + "/" + resource);
    },
    confirmOrder(order, data) {
        return this.apiPostRequest("/cvd/v1/register/" + order, data);
    },
    prepareOrder(data) {
        return this.apiPostRequest("/cvd/v1/prepare", data);
    },
    loadOrder(data) {
        return this.apiGetRequest("/cvd/v1/order/" + data);
    },
    sendCodeString(code) {
        return this.apiPostRequest("/cvd/v1/parse", {
            code: code
        });
    },
    apiPostRequest(uri, data, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            vcapi.post(uri, data, {
                params: pars,
                withCredentials: true,
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                this.$vm.$emit("showmsg", {
                    color: "error",
                    msg: this.$vm.$t("error.api")
                })
                reject(error);
            });
        })
    },
    apiMultipartPostRequest(uri, data, file, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        let formdata = new FormData();
        formdata.append("data", JSON.stringify(data));
        formdata.append("file", file, file.name);
        return new Promise((resolve, reject) => {
            vcapi.post(uri, formdata, {
                params: pars,
                withCredentials: true,
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    apiGetRequest(uri, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            vcapi.get(uri, {
                params: pars
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                this.$vm.$emit("showmsg", {
                    color: "error",
                    msg: this.$vm.$t("error.api")
                })
                reject(error);
            });
        })
    },
    setVm(vm) {
        this.$vm = vm;
        this.$vm.$i18n.locale = this.getSavedLocaleAsStr();
    },
    getSavedLocaleAsStr() {
        const lang = localStorage.getItem("lang");
        if(!lang) this.setLocale("lv");
        return lang ? lang : "lv";
    },
    setLocale(v) {
        localStorage.setItem("lang", v);
        this.$vm.$i18n.locale = v;
    },
    isApple() {
        if (navigator.appVersion.indexOf("Mac OS") != -1) return true;
        return false;
    },
    isAndroid() {
        if (navigator.appVersion.indexOf("Android") != -1) return true;
        return false;
    },
    isWindows() {
        if (navigator.appVersion.indexOf("Win") != -1) return true;
        return false;
    },
}
export default api;