import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import filters from './filters';

import api from '@/api.js'
import vuetify from './plugins/vuetify'

Vue.prototype.$api = api;
Vue.prototype.$omniva = 3.00;
Vue.config.productionTip = false
Vue.use(filters)
Vue.component("basket-stepper", () => import("@/components/BasketStepper.vue"))
Vue.component("basket-table", () => import("@/components/BasketTable.vue"))

const vm = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

vm.$api.setVm(vm);

Vue.prototype.$rules = {
  required: (value) => !!value || vm.$t("error.required"),
  counter: (value) => value.length <= 20 || "Max 20 characters",
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !value || pattern.test(value) || vm.$t("error.email");
  },
  phone: (value) => {
    const pattern = /^[+]?[(]?[0-9]{0,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{5}$/im;
    return !value || pattern.test(value) || vm.$t("error.phone");
  },
};